<template>
  <div class="banner">
    <v-row>
      <v-col cols="12">
        <v-img
          src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/letterhead_new_cropped.png"
          class="imgLogo"
          height="155"
          contain
        />
      </v-col>
    </v-row>
    <v-row
      class="contactRow"
      justify="center"
    >
      <v-col
      	xs="12"
      	class="black--text"
      >
        <div class="d-flex">
          <v-icon
            color="black"
            large
            class="hover-pointer"
            @click="socialMedia('https://www.facebook.com/niagamasinsurance.bn')"
          >
            mdi-facebook
          </v-icon>
          <v-icon
            color="black"
            large
            class="hover-pointer"
            @click="socialMedia('https://www.instagram.com/niagamasinsurance.bn')"
          >
            mdi-instagram
          </v-icon>
          <h2 class="font-weight-medium ml-2">
            niagamasinsurance.bn
          </h2>
        </div>
        <div
          class="d-flex mt-2 hover-pointer"
          @click="socialMedia(`https://api.whatsapp.com/send?phone=${ctaNumber}`)"
        >
          <v-icon
            color="black"
            large
            class="align-start"
          >
            mdi-whatsapp
          </v-icon>
          <div class="ml-2 d-block d-sm-flex">
            <h2 class="font-weight-medium mr-2">
              +673 891 1088
            </h2>
            <h2 class="font-weight-medium">
              (Brunei Muara / Temburong)
            </h2>
          </div>
        </div>
        <div
          class="d-flex mt-2 hover-pointer"
          @click="socialMedia(`https://api.whatsapp.com/send?phone=${ctaNumber2}`)"
        >
          <v-icon
            color="black"
            large
            class="align-start"
          >
            mdi-whatsapp
          </v-icon>
          <div class="ml-2 d-block d-sm-flex">
            <h2 class="font-weight-medium mr-2">
              +673 888 1088
            </h2>
            <h2 class="font-weight-medium">
              (Tutong / Belait)
            </h2>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'BannerLogo',
  data: () => ({
    ctaNumber: process.env.VUE_APP_CTA_NUM,
    ctaNumber2: process.env.VUE_APP_CTA_NUM_2,
  }),
  methods: {
    socialMedia(url) {
      window.open(url, '_blank')
    }
  },
}
</script>

<style scoped>
.contactRow {
  padding-top: 185px;
}
.imgLogo {
  filter: drop-shadow(1px 1px 2px #222);
}
@media (max-width: 600px) {
  .contactRow {
    padding-top: 90px;
  }
}
</style>
