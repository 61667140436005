<template>
  <v-container id="hlp" class="mt-15">
    <h2 class="font-weight-medium ngPurple--text pt-2">Health & Lifestyle Program</h2>
    <v-row class="mt-3">
      <v-col cols="12" sm="6" class="d-flex">
        <v-hover
          v-slot="{ hover }"
          close-delay="20"
        >
          <v-card
            :elevation="hover ? 4 : 1"
            :class="{ 'on-hover': hover }"
          >
            <v-carousel
              height="400"
              cycle
              interval="4000"
              hide-delimiter-background
              :show-arrows="false"
              class="carousel rounded rounded-b-0 d-block d-sm-none"
            >
              <v-carousel-item
                v-for="(image, i) in images"
                :key="i"
                :src="image.image_url"
              >
              </v-carousel-item>
            </v-carousel>
            <v-card-text>
              <p>
                Niagamas is excited to introduce a vibrant health and lifestyle program that brings our employees together in a fun and engaging way!
              </p>
              <p>
                Every month, we dive into a new sports activity, from invigorating jogs to lively bowling nights and high-energy boxing sessions. This ever-changing lineup keeps things fresh and exciting, ensuring there’s something for everyone to enjoy.
              </p>
              <p>
                Not only does this initiative promote fitness, but it also strengthens our team spirit and builds lasting connections among colleagues. Get ready for a year filled with fun, camaraderie, and plenty of active adventures!
              </p>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex">
        <v-carousel
          height="400"
          cycle
          interval="4000"
          hide-delimiter-background
          :show-arrows-on-hover="true"
          class="carousel rounded d-none d-sm-block"
        >
          <v-carousel-item
            v-for="(image, i) in images"
            :key="i"
            :src="image.image_url"
          >
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HLP',
  data: () => ({
    images: [
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/hlp/hlp1.jpg' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/hlp/hlp2.jpg' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/hlp/hlp3.jpg' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/hlp/hlp4.jpg' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/hlp/hlp5.jpg' },
    ],
  })
}
</script>

<style scoped>
.v-card {
  transition: 0.2s ease-out;
}
</style>
