<template>
  <div id="careline" class="mt-15">
    <v-img
      src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/careline.jpg"
      height="275"
    >
      <div class="overlayCareline d-flex align-center">
        <v-row class="justify-center">
          <v-col
            cols="11"
            md="12"
            xl="8"
          >
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="9"
                >
                  <h1 class="font-weight-regular white--text text-no-wrap mb-2">
                    24/7 Customer careline
                  </h1>
                  <div
                    v-for="(item, i) in careline"
                    :key="i"
                    class="d-flex align-center"
                    :class="{ 'mb-3': i != 2 }"
                  >
                    <v-icon
                      color="waGreen"
                      :small="$vuetify.breakpoint.xsOnly ? true : false"
                    >
                      mdi-check
                    </v-icon>
                    <div class="carelineText ml-2 white--text text-no-wrap">
                      {{ item }}
                    </div>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="d-flex align-center"
                >
                  <v-btn
                    class="rounded-pill px-8 white--text"
                    color="#66447E"
                    depressed
                    href="tel:6737261013"
                    target="_blank"
                  >
                    <v-icon class="mr-3">
                      mdi-phone
                    </v-icon>
                    <div class="waText">
                      Call us now
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </div>
    </v-img>
  </div>
</template>

<script>
export default {
  name: 'Careline',
  data: () => ({
    careline: [
      'Insurans Islam TAIB General Takaful',
      'Takaful Brunei Am',
      'National Insurance Company Berhad',
    ],
  })
}
</script>

<style>
.overlayCareline {
  background-color: rgba(84, 163, 220, 0.4);
  height: 275px;
}
.carelineText {
  font-size: 15px;
}
</style>