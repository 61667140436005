<template>
  <div class="mt-15">
    <div class="footerDiv pt-12 pb-5">
      <v-container>
        <v-row justify="center">
          <v-col
            cols="12"
            md="12"
            xl="8"
          >
            <v-divider color="white"></v-divider>
          </v-col>
        </v-row>
        <v-row class="white--text" justify="center">
          <v-col
            cols="12"
            md="6"
            xl="4"
          >
            <p class="font-weight-bold px-2">
              General Inquiries
            </p>
            <div
              v-for="(item,i) in general"
              :key="i"
              class="d-flex px-2"
            >
              <p>{{ item.label }}</p>
              <v-spacer></v-spacer>
              <p>{{ item.value }}</p>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            xl="4"
          >
            <p class="font-weight-bold px-2">
              Working Hours
            </p>
            <div
              v-for="(item,i) in worktimes"
              :key="i"
              class="d-flex px-2"
            >
              <v-row>
                <v-col cols="7" md="5" lg="6"><p>{{ item.day }}</p></v-col>
                <v-col cols="5" md="7" lg="6"><p>{{ item.time }}</p></v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row class="white--text" justify="center">
          <v-col
            cols="12"
            md="12"
            xl="8"
          >
            <p class="font-weight-bold px-2">
              Niagamas Insurance Services Sdn Bhd
            </p>
            <div class="d-flex px-2">
              <p>Provide a tailored package of various general insurance solutions from Insurans Islam TAIB General Takaful, Takaful Brunei Am and National Insurance Company Berhad to meet the unique business needs of our corporate clients.</p>
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col
            cols="12"
            md="12"
            xl="8"
          >
            <v-divider color="white"></v-divider>
          </v-col>
        </v-row>
        <v-row class="white--text" justify="center">
          <v-col
            cols="12"
            xl="8"
            class="text-center"
          >
            {{ new Date().getFullYear() }} and beyond — Niagamas Insurance Services Sdn. Bhd. is powered by <span class="font-weight-bold hover-pointer" @click="synapseBN()">SynapseBN</span>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data: () => ({
    general: [
      { label: 'Quotation', value: '+673 891 1088 (Brunei Muara / Temburong)' },
      { label: '', value: '+673 888 1088 (Tutong / Belait)' },
      { label: 'Hotline', value: '+673 726 1013' },
      { label: 'Email', value: 'inquiry@niagamasinsurance.com' }
    ],
    worktimes: [
      { day: 'Monday - Thursday', time: '8.30 - 11.30am, 1.30 - 4.30pm' },
      { day: 'Friday', time: '8.30 - 11.30am, 2.00 - 4.30pm' },
      { day: 'Saturday', time: '8.30 - 11.30am' },
      { day: 'Sunday (and public holidays)', time: 'Closed' },
    ],
  }),
  methods: {
    synapseBN() {
      window.open('https://synapsebn.com', '_blank')
    }
  },
}
</script>

<style>
.footerDiv {
  background-color: #66447E;
}
</style>