import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        ngBlue: '#54A3DC',
        ngDarkBlue: '#023C86',
        ngOrange: '#F05A23',
        ngYellow: '#F5AD19',
        ngPurple: '#6F3692',
        ngBrown: '#E3CAAB',
        waGreen: '#25D366',
      }
    }
  }
});
