<template>
  <div>
    <Promo />
    <v-row justify="center">
      <v-col
        cols="11"
        md="12"
        xl="8"
      >
        <WhatsNew />
        <About />
        <Highlight />
      </v-col>
    </v-row>
    <Careline />
    <v-row justify="center">
      <v-col
        cols="11"
        md="12"
        xl="8"
      >
        <Product />
        <Relationship />
        <Client />
        <Internship />
        <HLP />
        <Branch />
        <Contact />
      </v-col>
    </v-row>
    <v-snackbar
      v-if="$route.params.message"
      v-model="snackbar"
      top
      text
      color="error"
      class="mt-16"
    >
      {{ $route.params.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          color="error"
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Promo from '@/components/Promo'
import WhatsNew from '@/components/WhatsNew'
import About from '@/components/About'
import Highlight from '@/components/Highlight'
import Careline from '@/components/Careline'
import Product from '@/components/Product'
import Relationship from '@/components/Relationship'
import Client from '@/components/Client'
import Internship from '@/components/Internship'
import HLP from '@/components/HLP'
import Branch from '@/components/Branch'
import Contact from '@/components/Contact'

export default {
  name: 'Home',
  components: {
    Promo,
    WhatsNew,
    About,
    Highlight,
    Careline,
    Product,
    Relationship,
    Client,
    Internship,
    HLP,
    Branch,
    Contact,
  },
  data: () => ({
    snackbar: true,
  })
}
</script>
