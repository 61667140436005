<template>
  <v-container id="about" class="mt-5 mt-sm-15">
    <h2 class="font-weight-medium ngPurple--text pt-2">About us</h2>
    <v-row class="mt-2">
      <v-col cols="12" sm="6" class="d-flex">
        <v-hover
          v-slot="{ hover }"
          close-delay="20"
        >
          <v-card
            :elevation="hover ? 4 : 1"
            :class="{ 'on-hover': hover }"
          >
            <v-img
              src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/about.jpg"
              class="rounded rounded-b-0 d-block d-sm-none"
            />
            <v-card-text>
              <p>
                Niagamas Insurance Services Sdn Bhd, established in 2016 by industry veteran Madam Yong, has grown to seven branches across Brunei. With over 40 years of experience, Madam Yong has led the agency to become a trusted name in insurance.
              </p>
              <p>
                As registered agents for Takaful Brunei Am, Insurans Islam TAIB General Takaful, and National Insurance Company Berhad, we offer a diverse range of insurance packages to our clients.
              </p>
              <p class="mb-0">
                Serving over 10,000+ clients and managing $5,000,000 [millions] in premiums annually, we are now eager to extend our expertise in general insurance to prospective corporate clients, providing personalized, value-based, and value-added insurance services.
              </p>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" sm="6" class="d-none d-sm-flex">
        <v-img
          src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/about.jpg"
          class="rounded"
        />
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12" sm="6" class="d-flex">
        <v-hover
          v-slot="{ hover }"
          close-delay="20"
        >
          <v-card
            :elevation="hover ? 4 : 1"
            :class="{ 'on-hover': hover }"
          >
            <v-card-text>
              <h2 class="font-weight-medium mb-3">
                Our Mission
              </h2>
              <p class="mb-0">
                We strive to deliver tailored and affordable general
                insurance solutions to our customers’s satisfaction.
              </p>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex">
        <v-hover
          v-slot="{ hover }"
          close-delay="20"
        >
          <v-card
            :elevation="hover ? 4 : 1"
            :class="{ 'on-hover': hover }"
          >
            <v-card-text>
              <h2 class="font-weight-medium mb-3">
                Our Vision
              </h2>
              <p class="mb-0">
                We want to be the market leader in insurance agency
                services in Brunei that provides the best value-based
                insurance policies, whilst aligning with the goals and
                interests of our customers and principals.
              </p>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-hover
      v-slot="{ hover }"
      close-delay="20"
    >
      <v-card
        :elevation="hover ? 4 : 1"
        :class="{ 'on-hover': hover }"
        class="mt-6"
      >
        <v-card-text>
          <h2 class="font-weight-medium mb-3">
            Awards
          </h2>
        </v-card-text>
        <v-row justify="center">
          <v-col cols="12" sm="4">
            <v-img src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/awards/award1.png"></v-img>
          </v-col>
          <v-col cols="12" sm="4">
            <v-img src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/awards/award2.png"></v-img>
          </v-col>
          <v-col cols="12" sm="4">
            <v-img src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/awards/award3.png"></v-img>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>
  </v-container>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {
      windowWidth: window.innerWidth
    }
  },
  computed: {
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }
}
</script>

<style scoped>
.v-card {
  transition: 0.2s ease-out;
}
</style>
