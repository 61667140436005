<template>
  <v-container id="contact" class="mt-15">
    <h2 class="font-weight-medium ngPurple--text pt-2">Contact Us</h2>
    <v-row class="my-3">
      <v-col
        cols="12"
        sm="6"
        md="7"
        lg="8"
      >
        <p>For any inquiries or insurance quotes, please reach out to us via the form or directly <a href="mailto:inquiry@niagamasinsurance.com">inquiry@niagamasinsurance.com</a>. We look forward to hear from you!</p>
        <v-img
          src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/contact.jpg"
          class="rounded mx-auto mx-sm-0"
          min-height="420"
          max-width="740"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="5"
        lg="4"
        class="mx-auto"
      >
        <v-hover
          v-slot="{ hover }"
          close-delay="20"
        >
          <v-card
            :elevation="hover ? 4 : 1"
            :class="{ 'on-hover': hover }"
          >
            <v-tabs
              v-model="tab"
              slider-color="transparent"
              class="mb-5"
              height="55"
              grow
            >
              <v-tab
                :class="tab == 1 ? 'grey lighten-3' : 'black--text'"
                :ripple="false"
              >
                General
              </v-tab>
              <v-tab
                :class="tab == 0 ? 'grey lighten-3' : 'black--text'"
                :ripple="false"
              >
                Company
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-form
                  ref="waForm"
                  v-model="isCustomerValid"
                  lazy-validation
                  class="mt-2"
                >
                  <v-text-field
                    v-model="customerForm.name"
                    label="Your Name"
                    :rules="required"
                    outlined
                    dense
                    class="mx-5"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="customerForm.contact"
                    label="Phone Number"
                    :rules="contactRules"
                    outlined
                    dense
                    class="mx-5"
                    required
                  ></v-text-field>
                  <v-textarea
                    v-model="customerForm.message"
                    label="Message"
                    :rules="required"
                    outlined
                    class="mx-5"
                    required
                  ></v-textarea>
                  <v-btn
                    :disabled="!isCustomerValid"
                    color="waGreen"
                    width="90%"
                    class="ma-5 white--text"
                    depressed
                    @click="$refs.waForm.validate(); sendMessage()"
                  >
                    <v-icon class="mr-3">
                      mdi-whatsapp
                    </v-icon>
                    Send Message
                  </v-btn>
                </v-form>
              </v-tab-item>
              <v-tab-item>
                <v-form
                  ref="emailForm"
                  v-model="isCompanyValid"
                  lazy-validation
                  class="mt-2"
                >
                  <v-text-field
                    v-model="companyForm.company"
                    label="Name of Company"
                    :rules="companyRules"
                    outlined
                    dense
                    class="mx-5"
                    required
                    :disabled="loading"
                  ></v-text-field>
                  <v-text-field
                    v-model="companyForm.name"
                    label="Your Name"
                    :rules="required"
                    outlined
                    dense
                    class="mx-5"
                    required
                    :disabled="loading"
                  ></v-text-field>
                  <v-text-field
                    v-model="companyForm.contact"
                    label="Your Phone Number"
                    :rules="contactRules"
                    outlined
                    dense
                    class="mx-5"
                    required
                    :disabled="loading"
                  ></v-text-field>
                  <v-text-field
                    v-model="companyForm.email"
                    label="Company's Email / Your Email"
                    :rules="emailRules"
                    outlined
                    dense
                    class="mx-5"
                    required
                    :disabled="loading"
                  ></v-text-field>
                  <v-select
                    v-model="companyForm.insurance"
                    :items="insurances"
                    label="Type of Insurance"
                    :rules="required"
                    class="mx-5"
                    dense
                    outlined
                    :disabled="loading"
                  ></v-select>
                  <v-file-input
                    v-model="companyForm.files"
                    label="Choose File"
                    prepend-icon=""
                    append-icon="mdi-paperclip"
                    accept="application/pdf, image/jpeg, image/png"
                    hint="Upload copy of previous insurance policy or any relevant documents"
                    persistent-hint
                    show-size
                    :rules="fileRules"
                    class="mx-5"
                    outlined
                    dense
                    :disabled="loading"
                  ></v-file-input>
                  <v-btn
                    :disabled="!isCompanyValid || loading"
                    color="#66447E"
                    class="ma-5 mb-2 white--text"
                    depressed
                    width="90%"
                    @click="$refs.emailForm.validate(); sendCompanyForm()"
                  >
                    <span v-if="!loading">Submit</span>
                    <v-progress-circular
                      v-else
                      :size="20"
                      :width="3"
                      indeterminate
                    ></v-progress-circular>
                  </v-btn>
                  <div class="text-center mb-4">
                    <small
                      v-if="loading"
                      class="grey--text"
                    >
                      This may take a while. Please do not leave the page
                    </small>
                  </div>
                </v-form>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-hover>
        <v-snackbar
          v-model="snackbar.show"
          class="mt-16"
          :color="snackbar.color"
          top
        >
          {{ snackbar.text }}

          <template v-slot:action="{ attrs }">
            <v-icon
              color="white"
              v-bind="attrs"
              @click="snackbar.show = false"
            >
              mdi-close
            </v-icon>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Contact',
  data: () => ({
    tab: null,
    isCustomerValid: false,
    isCompanyValid: false,
    loading: false,
    snackbar: {
      show: false,
      text: '',
      color: '',
    },
    insurances: [
      'Motor Insurance', 'Travel Insurance', 'Fire Insurance',
      'Workmen Compensation', 'Public Liability', 'Contractor All Risk'
    ],
    required: [
      v => !!v || 'This field is required',
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    companyRules: [
      v => !!v || 'This field is required',
      v => /^[a-zA-Z]/.test(v) || 'Must start with letter',
      v => /^[0-9a-zA-Z. ]+$/.test(v) || 'Must be alphanumeric',
      v => /[0-9a-zA-Z. ]{4,}/.test(v) || 'Must be at least 4 characters or more',
      v => !/[.]{2,}/.test(v) || 'Must not contain a sequence of .',
      v => /^\S+(?: \S+)*$/.test(v) || 'Must not contain consecutive or end with white-space',
    ],
    contactRules: [
      v => !!v || 'This field is required',
      v => /^\+[0-9(]/.test(v) || 'Must start with country code, e.g. +673',
      v => /^\+[0-9\-().\s]{10,}$/.test(v) || 'Must be valid contact number',
      v => !/[+.\-()]{2,}/.test(v) || 'Must not contain a sequence of special characters',
      v => /^\S+(?: \S+)*$/.test(v) || 'Must not contain consecutive or end with white-space',
    ],
    fileRules: [
      value => !value || value.size < 25000000 || 'You file has to be lower than 25MB',
    ],
    customerForm: {
      name: '',
      contact: '',
      message: '',
    },
    companyForm: {
      name: '',
      company: '',
      contact: '',
      email: '',
      insurance: '',
      files: []
    },
  }),
  computed: {
    getAttachment() {
      return this.companyForm.files;
    }
  },
  watch: {
    getAttachment() {
      if (this.companyForm.files === null) return
      const reader = new FileReader()
      reader.readAsDataURL(this.companyForm.files)
      reader.onload = () => {
        this.companyForm.files.content = reader.result.split(',')[1]
        this.companyForm.files.filename = this.companyForm.files.name
      }
    }
  },
  methods: {
    sendMessage() {
      if (this.customerForm.message.trim() !== "") {
        window.open(`https://api.whatsapp.com/send?phone=${process.env.VUE_APP_CTA_NUM}&text=${this.customerForm.message}`, '_blank')
      }
    },
    sendCompanyForm() {
      if (this.companyForm.name.trim() !== "" &&
        this.companyForm.company.trim() !== "" &&
        this.companyForm.contact.trim() !== "" &&
        this.companyForm.email.trim() !== "" &&
        this.companyForm.insurance.trim() !== "") {

        this.loading = true
        const apiUrl = process.env.VUE_APP_API_URL
        axios.post(apiUrl + '/api/v1/website/messages', this.companyForm).then(
          (res) => {
            this.loading = false
            this.snackbar = {
              show: true,
              text: res.data.message,
              color: 'success'
            }
            this.$refs.emailForm.reset()
            this.companyForm = {
              name: '',
              company: '',
              contact: '',
              email: '',
              insurance: '',
              files: null
            }
          },
          () => {
            this.loading = false
            this.snackbar = {
              show: true,
              text: 'Oh no something went wrong while sending your message. Please try again later!',
              color: 'error'
            }
          }
        )
      }
    }
  },
}
</script>

<style scoped>
.v-tab:hover:before,
.v-tab:focus:before {
  display: none;
}
.v-card {
  transition: 0.2s ease-out;
}
</style>
