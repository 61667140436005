<template>
  <v-container id="relationship" class="mt-15">
    <h2 class="font-weight-medium ngPurple--text pt-2">Corporate Relationships</h2>
    <v-row class="mt-3">
      <v-col cols="12" sm="6" class="d-flex">
        <v-hover
          v-slot="{ hover }"
          close-delay="20"
        >
          <v-card
            :elevation="hover ? 4 : 1"
            :class="{ 'on-hover': hover }"
          >
            <v-img
              src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/corporate.png"
              class="rounded rounded-b-0 d-block d-sm-none"
            />
            <v-card-text>
              <h2 class="font-weight-medium mb-3">
                Building Strong Corporate Relationships
              </h2>
              <p>
                At Niagamas Insurance Services Sdn Bhd, our core values of trust, reliability, and mutual success form the foundation of our professional relationships with corporate partners. We are committed to delivering excellence through innovative and personalized solutions tailored to meet the unique needs of every client.
              </p>
              <ul class="pb-4">
                <li>
                  <span class="font-weight-medium">Market Leadership:</span> Recognised as a leading insurance agent in Brunei, we set the benchmark for providing innovative, client-focused insurance services.
                </li>
                <li>
                  <span class="font-weight-medium">Value-Based Policies:</span> Affordable and high-quality insurance plans that align with corporate financial strategies and objectives.
                </li>
                <li>
                  <span class="font-weight-medium">Tailored Solutions:</span> Customised insurance packages designed to address the specific needs and challenges of each corporate client.
                </li>
                <li>
                  <span class="font-weight-medium">Service Excellence:</span> A steadfast focus on exceeding expectations to build long-term, successful partnerships.
                </li>
              </ul>
              <p>
                Our professionalism and forward-thinking approach ensure enduring and thriving corporate relationships. If you are interested in collaborating with us for corporate projects or partnerships, please contact us at <a href="mailto:inquiry@niagamasinsurance.com">inquiry@niagamasinsurance.com</a>
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="rounded-pill px-8 white--text ml-3 mb-3"
                color="#66447E"
                depressed
                @click="$vuetify.goTo('#contact')"
              >
                <v-icon class="mr-3">
                  mdi-message-outline
                </v-icon>
                Contact Us
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex">
        <v-img
          src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/corporate.png"
          class="rounded d-none d-sm-block"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Relationship',
}
</script>

<style scoped>
.v-card {
  transition: 0.2s ease-out;
}
</style>
