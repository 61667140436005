<template>
  <div class="banner">
    <v-row class="rowTitle ml-0 ml-md-8">
      <v-col
        xs="12"
        sm="9"
        md="4"
        lg="5"
        class="black--text ml-0 ml-md-15"
      >
        <div>
          <h2 class="font-weight-regular">
            Recognised Nationwide
          </h2>
          <p class="font-weight-light">
            We are an authorized agent for Insurans Islam TAIB General Takaful, Takaful Brunei Am and National Insurance Company Berhad.
          </p>
          <p class="font-weight-light">
            Click the button below to view all of our available products and services!
          </p>
          <v-btn
            class="rounded-pill px-8 white--text"
            color="#66447E"
            depressed
            @click="$vuetify.goTo('#product')"
          >
            View Products & Services
          </v-btn>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="5"
      >
        <div class="d-flex ml-0 ml-md-12">
          <img
            src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/companies/taib.png"
            class="ma-5"
            :height="$vuetify.breakpoint.smAndDown ? '55' : '110'"
            contain
          />
          <img
            src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/companies/nicb.png"
            class="ma-5"
            :height="$vuetify.breakpoint.smAndDown ? '55' : '110'"
            contain
          />
          <img
            src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/companies/tba.png"
            class="ma-5 my-auto"
            :height="$vuetify.breakpoint.smAndDown ? '40' : '80'"
            contain
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'BannerProductService',
}
</script>

<style scoped>
.rowTitle {
  padding-top: 100px;
}
</style>
