<template>
  <v-container id="internship" class="mt-15">
    <h2 class="font-weight-medium ngPurple--text pt-2">Internship Program</h2>
    <v-row class="mt-3">
      <v-col cols="12" sm="6" class="d-flex">
        <v-img
          src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/learning.jpg"
          class="rounded d-none d-sm-block"
        />
      </v-col>
      <v-col cols="12" sm="6" class="d-flex">
        <v-hover
          v-slot="{ hover }"
          close-delay="20"
        >
          <v-card
            :elevation="hover ? 4 : 1"
            :class="{ 'on-hover': hover }"
          >
            <v-img
              src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/learning.jpg"
              class="rounded rounded-b-0 d-block d-sm-none"
            />
            <v-card-text>
              <p>
                Discover Your Future with Us!
              </p>
              <p>
                Our company offers the perfect environment for students to intern, gain practical career experience, understand workplace ethics, and build a professional image for future success.
              </p>
              <p>
                Ready to start your journey? Send your inquiry and proposal to <a href="mailto:career@niagamasinsurance.com">career@niagamasinsurance.com</a> or reach out through CONTACT US. We're excited to assist you!
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="rounded-pill px-8 white--text ml-3 mb-3"
                color="#66447E"
                depressed
                @click="$vuetify.goTo('#contact')"
              >
                <v-icon class="mr-3">
                  mdi-message-outline
                </v-icon>
                Contact Us
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Internship',
}
</script>

<style scoped>
.v-card {
  transition: 0.2s ease-out;
}
</style>
