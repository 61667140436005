<template>
  <v-container id="client" class="mt-15">
    <h2 class="font-weight-medium ngPurple--text pt-2">Proven Track Record</h2>
    <div ref="slider" class="keen-slider my-8">
      <div
        v-for="(item, i) in clients"
        :key="i"
        class="keen-slider__slide"
      >
        <v-img
          :src="item.image_url"
          :height="item.height || '130'"
          :class="item.height ? 'mt-5' : 'mt-0'"
          contain
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'

const animation = { duration: 16000, easing: (t) => t }

export default {
  name: 'Client',
  data: () => ({
    clients: [
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/clients/client1.png' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/clients/client2.png' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/clients/client3.png', height: 90 },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/clients/client4.png' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/clients/client5.png', height: 90 },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/clients/client6.png' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/clients/client7.png' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/clients/client8.png' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/clients/client9.png' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/clients/client10.png' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/clients/client11.png' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/clients/client12.png' },
      { image_url: 'https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/clients/client13.png' }
    ]
  }),
  mounted() {
    this.slider = new KeenSlider(this.$refs.slider, {
      loop: true,
      renderMode: "performance",
      slides: { perView: "auto" },
      created(s) {
        s.moveToIdx(5, true, animation)
      },
      updated(s) {
        s.moveToIdx(s.track.details.abs + 5, true, animation)
      },
      animationEnded(s) {
        s.moveToIdx(s.track.details.abs + 5, true, animation)
      },
    })
  },
  beforeDestroy() {
    if (this.slider) this.slider.destroy();
  }
}
</script>

<style>
.keen-slider__slide {
  min-width: 400px;
  max-width: 400px;
}
</style>