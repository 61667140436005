var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-5 mt-sm-15",attrs:{"id":"about"}},[_c('h2',{staticClass:"font-weight-medium ngPurple--text pt-2"},[_vm._v("About us")]),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-hover',{attrs:{"close-delay":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 4 : 1}},[_c('v-img',{staticClass:"rounded rounded-b-0 d-block d-sm-none",attrs:{"src":"https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/about.jpg"}}),_c('v-card-text',[_c('p',[_vm._v(" Niagamas Insurance Services Sdn Bhd, established in 2016 by industry veteran Madam Yong, has grown to seven branches across Brunei. With over 40 years of experience, Madam Yong has led the agency to become a trusted name in insurance. ")]),_c('p',[_vm._v(" As registered agents for Takaful Brunei Am, Insurans Islam TAIB General Takaful, and National Insurance Company Berhad, we offer a diverse range of insurance packages to our clients. ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Serving over 10,000+ clients and managing $5,000,000 [millions] in premiums annually, we are now eager to extend our expertise in general insurance to prospective corporate clients, providing personalized, value-based, and value-added insurance services. ")])])],1)]}}])})],1),_c('v-col',{staticClass:"d-none d-sm-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-img',{staticClass:"rounded",attrs:{"src":"https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/about.jpg"}})],1)],1),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-hover',{attrs:{"close-delay":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 4 : 1}},[_c('v-card-text',[_c('h2',{staticClass:"font-weight-medium mb-3"},[_vm._v(" Our Mission ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" We strive to deliver tailored and affordable general insurance solutions to our customers’s satisfaction. ")])])],1)]}}])})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-hover',{attrs:{"close-delay":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 4 : 1}},[_c('v-card-text',[_c('h2',{staticClass:"font-weight-medium mb-3"},[_vm._v(" Our Vision ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" We want to be the market leader in insurance agency services in Brunei that provides the best value-based insurance policies, whilst aligning with the goals and interests of our customers and principals. ")])])],1)]}}])})],1)],1),_c('v-hover',{attrs:{"close-delay":"20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mt-6",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 4 : 1}},[_c('v-card-text',[_c('h2',{staticClass:"font-weight-medium mb-3"},[_vm._v(" Awards ")])]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-img',{attrs:{"src":"https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/awards/award1.png"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-img',{attrs:{"src":"https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/awards/award2.png"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-img',{attrs:{"src":"https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/awards/award3.png"}})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }