<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      color="ngBrown"
    >
      <v-list>
        <v-list-item>
          <div>
            <img
              src="https://niagamas-production.sgp1.cdn.digitaloceanspaces.com/images/letterhead_new_cropped.png"
              height="50px"
              contain
            />
          </div>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item
          v-if="isPromoPresent"
          link
          @click="$vuetify.goTo('#whats-new'); drawer = false"
          href="#whats-new"
        >
          <v-list-item-icon class="justify-center">
            <v-icon>mdi-star-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">
              What's new
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="(item, i) in navbar"
          :key="i"
          link
          :href="item.link"
          @click="$vuetify.goTo(item.link); drawer = false"
        >
          <v-list-item-icon class="justify-center">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      :color="color"
      :flat="flat"
      dark
      :class="{ expand: flat }"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="mr-4"
        v-if="isSm"
      >
        <v-btn text class="ml-10" :class="{ 'black--text': flat, 'white--text': !flat }">
          <v-icon class="mx-2">mdi-menu</v-icon>
          Menu
        </v-btn>
      </v-app-bar-nav-icon>
      <div class="mx-auto" v-else>
        <v-btn
          v-if="isPromoPresent"
          class="ma-1"
          :color="flat ? 'black' : 'white'"
          text
          small
          href="#whats-new"
          @click="$vuetify.goTo('#whats-new')"
        >
          What's new
        </v-btn>
        <v-btn
          v-for="(item,i) in navbar"
          :key="i"
          class="ma-1 font-weight-medium"
          :color="flat ? 'black' : 'white'"
          text
          small
          :href="item.link"
          @click="$vuetify.goTo(item.link)"
        >
          {{ item.name }}
        </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    color: String,
    flat: Boolean,
    isPromoPresent: Boolean,
  },
  data: () => ({
    drawer: null,
    isSm: false,
    navbar: [
      { name: 'About Us', icon: 'mdi-information-variant', link: '#about' },
      { name: 'Why Choose Us?', icon: 'mdi-shield-check-outline', link: '#highlight' },
      { name: 'Products & Services', icon: 'mdi-package-down', link: '#product' },
      { name: 'Corporate Relationships', icon: 'mdi-account', link: '#relationship' },
      { name: 'Career Opportunities', icon: 'mdi-clipboard-account', link: '#internship' },
      { name: 'Branches', icon: 'mdi-store-outline', link: '#branch' },
      { name: 'Contact Us', icon: 'mdi-phone', link: '#contact' }
    ]
  }),
  watch: {
    isSm(value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false;
        }
      }
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.isSm = window.innerWidth < 996;
    },
  },
}
</script>

<style scoped>
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}
</style>
