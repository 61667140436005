<template>
  <div class="banner">
    <v-row class="rowTitle ml-0 ml-md-8">
      <v-col
        xs="12"
        sm="9"
        md="5"
        class="black--text ml-0 ml-md-15"
      >
        <div>
          <h2 class="font-weight-regular">
            Free Services!
          </h2>
          <p class="font-weight-light">
            We offer FREE door-to-door collection and delivery of blue cards and road tax renewals upon our terms and condition
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'BannerDelivery',
}
</script>

<style scoped>
.rowTitle {
  padding-top: 130px;
}
</style>
