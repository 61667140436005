<template>
  <v-container
    v-if="banners.length !== 0"
    id="whats-new"
    class="mt-5 mt-sm-15"
  >
  	<h2 class="font-weight-medium ngPurple--text pt-2">What's new!</h2>
    <v-carousel
      height="auto"
      cycle
      hide-delimiters
      :show-arrows="$vuetify.breakpoint.smAndDown ? false : true"
      class="rounded-lg mt-3"
    >
      <v-carousel-item
        v-for="(item,i) in banners"
        :key="i"
        :src="item.image_url"
        :aspect-ratio="16/9"
      ></v-carousel-item>
    </v-carousel>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'WhatsNew',
  data: () => ({
    banners: []
  }),
  created() {
    this.fetchBanners()
  },
  methods: {
    async fetchBanners() {
      const apiUrl = process.env.VUE_APP_API_URL
      await axios.get(apiUrl + '/api/v1/website/banners').then(res => {
          res.data.data.forEach(banner => {
            this.banners.push(banner)
          })
        },
        () => {}
      )
      this.$parent.$emit('promoUpdate', this.banners)
    }
  }
}
</script>
